window.HorizontalImageSlider = (randomId) => {
	return {
		swiper: null,
		direction: 'forward',
		init() {
			setTimeout(() => {
				this.swiper = this.swiperFactory();
			}, 0);
		},
		swiperFactory( reverseDirection = false) {
			let config = {
				slidesPerView: 'auto',
				loop: true,
				spaceBetween: 10,
				speed: 10000,
				autoplay: {
					delay: 0,
					disableOnInteraction: true,
					reverseDirection: reverseDirection
				},
				a11y: true
			}
			return new Swiper('.swiper-container'+randomId, config);
		},
		forwardAutoplay() {
			if(this.direction === 'forward') {
				return;
			}
			this.direction = 'forward';
			this.swiper.destroy(true, true);
			this.swiper = this.swiperFactory();
		},
		reverseAutoplay() {
			if(this.direction === 'reverse') {
				return;
			}
			this.direction = 'reverse';
			this.swiper.destroy(true, true);
			this.swiper = this.swiperFactory(true);
		}

	};
};
